import React from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import Layout from '../../components/layouts/layout'
import Header from './Header'
import ItemList from './ItemList'
import Text1 from './Text1'
import Text2 from './Text2'

const LandingPageTemplate = props => (
  <>
    <Helmet
      title={props.pageContext.data?.seo_title}
      meta={[
        {
          name: 'description',
          content: props.pageContext.data?.seo_description
        },
        {
          name: 'keywords',
          content: props.pageContext.data?.keywords
        },
        {
          name: 'robots',
          content: props.pageContext.data?.index
        },
        {
          name: 'og:type',
          content: 'article'
        },
        {
          name: 'og:title',
          content: props.pageContext.data?.seo_title
        },
        {
          name: 'og:description',
          content: props.pageContext.data?.seo_description
        },
        {
          name: 'og:url',
          content: `https://amber-living.de${props.path}`
        },
        {
          name: 'og:site_name',
          content: 'AmberLiving'
        },
        {
          name: 'article:published_time',
          content: props.pageContext.data?.contentful.node.createdAt
        },
        {
          name: 'article:author',
          content: props.pageContext.data?.contentful.node.author
        }
      ]}
      link={[
        {
          rel: 'canonical',
          href: props.pageContext.data?.canonical
        }
      ]}
    />
    <Layout navigation={props.pageContext.navigation}>
      <Header pageContext={props.pageContext} />
      <Text1 pageContext={props.pageContext} />
      <ItemList
        items={props.pageContext.query}
        pathname={props.location.pathname}
      />
      {props.pageContext.data?.contentful.node.seoText && (
        <Text2 pageContext={props.pageContext} />
      )}
    </Layout>
  </>
)

export default LandingPageTemplate
