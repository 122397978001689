import styled from 'styled-components'
import { ImageCSS } from './Image'
import { TextCSS } from './Text'
import { TitleCSS } from './Title'

const Affect = styled.div`
  p {
    ${TextCSS}
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    ${TitleCSS}
  }
  img {
    ${ImageCSS}
  }
`

export default Affect
