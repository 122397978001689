import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const LinkGatsby = styled(Link)``

const LinkA = styled('a')``

const UniversalLink = props =>
  props.href ? <LinkA {...props} /> : <LinkGatsby {...props} />

export default UniversalLink
