import styled from 'styled-components'

const H3 = styled.h3`
  font-size: 12px;
  color: #000;
  letter-spacing: 1px;
  margin: 0 0 10px !important;

  @media screen and (min-width: 769px) and (max-width: 1400px) {
    font-size: 12px;
  }
`

export default H3
