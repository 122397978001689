import styled from 'styled-components'

const ActualPrice = styled.span`
  display: inline-block;
  padding-right: 15px;
  padding-bottom: 5px;
  &:first-child {
    color: #d21c0d;
    font-weight: 700;
  }

  &:last-child {
    color: #000000;
    font-weight: 700;
  }
`

export default ActualPrice
