const priceFormatter = price => {
  let newPrice = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  }).format(price)
  if (newPrice.endsWith(',00 €')) {
    newPrice = newPrice.replace(',00 €', ',- €')
  }
  return newPrice
}

export default priceFormatter
