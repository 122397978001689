import styled, { css } from 'styled-components'

const TextCSS = css`
  margin-top: 0;
  margin-bottom: 22px;
  color: #6b6b6b;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.661;
  letter-spacing: 1.26px;
  text-align: justify;
`

const Text = styled.p`
  ${TextCSS}
`
export { TextCSS }
export default Text
