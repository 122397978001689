import styled from 'styled-components'

const StyledLink = styled.a`
  color: black;
  text-decoration: none;
  display: block;
  overflow: hidden;
  max-width: 450px;
  height: auto;
  margin: 0 auto;
  position: relative;
  padding-bottom: 130%;
`

export default StyledLink
