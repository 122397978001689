import styled from 'styled-components'

const Price = styled.p`
  margin: 0;
  line-height: 1;
  color: #585858;
  font-weight: 700;
  font-family: Merriweather, serif;
  letter-spacing: 1px;
  font-size: 13px;
  text-align: justify;
  margin-bottom: 0 !important;
`

export default Price
