import styled from 'styled-components'

const StyledLink = styled.a`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  color: #000;
  line-height: 1.3;
  text-decoration: none;
  transition: all 700ms ease;
  font-weight: 400;
  font-size: 12px;

  &:hover {
    color: #f1574d;
    outline: none;
    text-decoration: none;
  }
`

export default StyledLink
