import React from 'react'
import ReactMarkdown from 'react-markdown'
import LandPagePost from '../../components/blocks/LandPagePost'

const Text1 = props => (
  <LandPagePost>
    <LandPagePost.Affect>
      <ReactMarkdown
        source={props.pageContext.data?.contentful.node.textBlock1.textBlock1}
      />
    </LandPagePost.Affect>
  </LandPagePost>
)

export default Text1
