import React from 'react'
import placeholderImage from '../../assets/placeholderImage'
import styled from 'styled-components'

const Image = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  transform: scale(1);
  max-height: 100%;
  margin: auto;
  transition: all ease 500ms;
`

const LoadableImage = ({ src, className, srcSet, ...props }) => (
  <Image
    {...props}
    src={placeholderImage}
    data-src={src}
    data-srcset={srcSet}
    className={className ? className + ' lazyload' : 'lazyload'}
  />
)

export default LoadableImage
