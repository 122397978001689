import styled from 'styled-components'
import ActualPrice from './ActualPrice'
import Discount from './Discount'
import DiscountTag from './DiscountTag'
import H3 from './H3'
import Price from './Price'
import StyledLink from './StyledLink'

const ProductItemDetails = styled.div`
  width: 100%;
  position: relative;
  padding-top: 10px;
  text-align: center !important;
`
ProductItemDetails.StyledLink = StyledLink
ProductItemDetails.H3 = H3
ProductItemDetails.Price = Price
ProductItemDetails.ActualPrice = ActualPrice
ProductItemDetails.Discount = Discount
ProductItemDetails.DiscountTag = DiscountTag

export default ProductItemDetails
