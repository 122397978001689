import React from 'react'
import ReactMarkdown from 'react-markdown'
import LandPagePost from '../../components/blocks/LandPagePost'

const Text2 = props => (
  <LandPagePost>
    <LandPagePost.Affect>
      <ReactMarkdown
        source={props.pageContext.data?.contentful.node.seoText.seoText}
      />
    </LandPagePost.Affect>
  </LandPagePost>
)

export default Text2
