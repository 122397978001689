import styled from 'styled-components'
import StyledLink from './StyledLink'

const ProductItem = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 5px;
  border-color: #9999991f;
  border-style: double;
  border-width: thin;
  padding: 5px;
  background-color: #eeeeee40;

  &:hover img {
    transform: scale(1.05);
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 2px;
  }
`
ProductItem.StyledLink = StyledLink

export default ProductItem
