import styled, { css } from 'styled-components'

const ImageCSS = css`
  max-width: 100%;
`

const Image = styled.img`
  ${ImageCSS}
`
export { ImageCSS }
export default Image
