import React from 'react'
import styled from 'styled-components'
import { grid } from 'styled-components-grid'
import GridUnit from 'styled-components-grid/dist/cjs/components/GridUnit'
import BlogBanner from '../../components/blocks/BlogBanner'
import UniversalLink from '../../components/elements/UniversalLink'

const Container = styled.div`
  ${grid({})};
  padding: 0 240px;
  position: relative;
  overflow: hidden;
  max-width: 1580px;
  margin: 40px auto 83px auto;

  @media screen and (max-width: 1399px) {
    padding: 0 60px;
    max-width: 1000px;
  }

  @media screen and (max-width: 576px) {
    padding: 0 25px;
    margin-bottom: 30px;
  }
`

const BlogHeader = item => {
  if (!item?.node?.mediaImage1) return null

  if (item.node.image1Clickid) {
    return (
      <UniversalLink
        rel='nofollow noopener'
        target='_blank'
        href={
          item.node.image1Clickid.indexOf('/') >= 0
            ? item.node.image1Clickid
            : '/clickout?click_id=' + item.node.image1Clickid
        }
      >
        <BlogBanner.Image
          fluid={item.node.mediaImage1.fluid}
          alt={item.node.image1Title}
        />
      </UniversalLink>
    )
  }

  return (
    <BlogBanner.Image
      fluid={item.node.mediaImage1.fluid}
      alt={item.node.image1Title}
    />
  )
}

const Header = props => (
  <Container>
    <GridUnit size={{ xs: 1, sm: 1 / 3 }}>
      <BlogBanner.Box>
        <BlogBanner.BoxText>
          {props.pageContext.data?.contentful.node.header}
        </BlogBanner.BoxText>
      </BlogBanner.Box>
    </GridUnit>
    <GridUnit size={{ xs: 1, sm: 2 / 3 }}>
      <BlogHeader {...props.pageContext.data?.contentful} />
    </GridUnit>
    <BlogBanner.FakeDiv />
  </Container>
)

export default Header
