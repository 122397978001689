import styled from 'styled-components'

const DiscountTag = styled.div`
  opacity: 0.8;
  font-family: Merriweather, serif;
  font-size: 15px;
  position: absolute;
  top: 60px;
  left: 5px;
  border-color: gray;
  padding: 5px 15px;
  display: inline-block;
  color: rgb(231, 45, 52);
  line-height: 12px;
  font-weight: 700;
  border-radius: 0 3px 3px 0;
  background: #ffffffbd;
  z-index: 1;
`

export default DiscountTag
