import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import Affect from './Affect'
import Text from './Text'
import Title from './Title'

const LandPagePost = styled.section`
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 15px;
  display: inherit;
  max-width: 580px;
  margin: 0 auto;

  ${down('sm')} {
    margin: 0 30px;
    padding: 0;
  }

  blockquote {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .gatsby-image-wrapper {
    position: unset !important;

    & > div {
      width: unset !important;
      padding-bottom: unset !important;
    }

    img {
      position: unset !important;
      top: unset !important;
      left: unset !important;
      width: unset !important;
      height: unset !important;
    }
  }
`

LandPagePost.Title = Title
LandPagePost.Text = Text
LandPagePost.Affect = Affect

export default LandPagePost
